.numbers h2{
    color:              var(--white);
    background-color:   var(--purple);
    width:              40.6vw;
    text-align:         center;
    padding:            18px 0 16px;
    margin:             80px auto;
    border-radius:      4px;
    box-shadow:         0 0 100px #FF00FF;
}

.numbersContainerOuter{
    display:            flex;
    justify-content:    space-evenly;
}

.numbersContainerInner{
    display:            flex;
    flex-direction:     column;
    gap:                16px;
    justify-content:    flex-start;
    align-items:        center;
}

.numbersContainerInner .numberInContainer{
    font-size:          9rem;
    text-shadow:        0 0 8px #FF00FF,
                        0 0 32px #FF00FF,
                        0 0 32px #FF00FF,
                        0 0 64px #FF00FF,
                        0 0 120px #FF00FF;
}

.numbersContainerInner .textInNumbersContainer{
    font-weight:        600;
    font-size:          2.2rem;
    width:              280px;
    text-align:         center;
}

.numbersText{
    font-size:          1.2rem;
    text-align:         center;
    width:              65vw;
    margin:             48px auto;
    z-index:            999;
}

.numberInContainer{
    width:              100px;
    height:             100px;
    text-align:         center;
    display:            inline;
    animation:          10s ease-in-out forwards infinite numberLightAnim;
}

.numbersText{
    z-index:            999;
}


@keyframes numberLightAnim{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(1.5px) brightness(2.75) hue-rotate(20deg) contrast(2);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(1.5px) brightness(2.75) hue-rotate(20deg) contrast(2);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(1.5px) brightness(2.75) hue-rotate(20deg) contrast(2);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }81%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.2%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.3%{
        filter:             blur(1.5px) brightness(2.75) hue-rotate(20deg) contrast(2);
    }84%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }85%{
        filter:             blur(1.5px) brightness(2.75) hue-rotate(20deg) contrast(2);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

@keyframes numberLightAnimSafari{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(7.5deg) contrast(1.2);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(7.5deg) contrast(1.2);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(7.5deg) contrast(1.2);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }82%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.2%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.3%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(7.5deg) contrast(1.2);
    }84%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }85.5%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(15deg) contrast(1.2);
    }90%{
        filter:             blur(.1px) brightness(1.05) hue-rotate(15deg) contrast(1.2);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

@keyframes numberLightAnimIOSChrome{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(.5px) brightness(1.65) hue-rotate(10deg) contrast(1.2);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(.5px) brightness(1.65) hue-rotate(10deg) contrast(1.2);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(.5px) brightness(1.65) hue-rotate(10deg) contrast(1.2);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }81%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.2%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.3%{
        filter:             blur(.5px) brightness(1.65) hue-rotate(10deg) contrast(1.2);
    }84%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }85%{
        filter:             blur(.5px) brightness(1.65) hue-rotate(10deg) contrast(1.2);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

@media screen and (max-width: 1300px) {
    .numbersContainerInner .textInNumbersContainer{
        font-size:          1.8rem;
        width:              180px;
    }
    
    .numbersContainerInner .numberInContainer{
        font-size:          6rem;
    }
}

@media screen and (max-width: 850px) {
    .numbersContainerInner .textInNumbersContainer{
        font-size:          1.5rem;
        width:              140px;
    }
    
    .numbersContainerInner .numberInContainer{
        font-size:          4rem;
    }

    
    .numberInContainer{
        animation:              10s ease-in-out forwards infinite numberLightAnim900px;
    }

    @keyframes numberLightAnim900px{
        0%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }8%{
            filter:             blur(1.5px) brightness(2) hue-rotate(25deg) contrast(2);
            transform:          scale(1.015);
        }18%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }30%{
            filter:             blur(1.5px) brightness(2) hue-rotate(25deg) contrast(2);
            transform:          scale(1.015);
        }47%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }60%{
            filter:             blur(1.5px) brightness(2) hue-rotate(25deg) contrast(2);
            transform:          scale(1.015);
        }70%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }80%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }81%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }83.2%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }83.3%{
            filter:             blur(1.5px) brightness(2) hue-rotate(25deg) contrast(2);
            transform:          scale(1.015);
        }84%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }85%{
            filter:             blur(1.5px) brightness(2) hue-rotate(25deg) contrast(2);
            transform:          scale(1.015);
        }100%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }
    }
}

@media screen and (max-width: 640px) {
    .numbersContainerInner .textInNumbersContainer{
        font-size:          1.2rem;
        width:              90px;
    }
    
    .numbersContainerInner .numberInContainer{
        font-size:          3rem;
    }

    .numbers h2{
        margin-top:         64px;
        margin-bottom:      48px;
    }

    .numbersText{
        width:              80vw;
    }
        
    .numberInContainer{
        animation:              10s ease-in-out forwards infinite numberLightAnim640px;
    }

    @keyframes numberLightAnim640px{
        0%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }8%{
            filter:             blur(0.5px) brightness(3) hue-rotate(30deg);
            transform:          scale(1.018);
        }18%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }30%{
            filter:             blur(0.5px) brightness(3) hue-rotate(30deg);
            transform:          scale(1.018);
        }47%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }60%{
            filter:             blur(0.5px) brightness(3) hue-rotate(30deg);
            transform:          scale(1.018);
        }70%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }80%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }81%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }83.2%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }83.3%{
            filter:             blur(0.5px) brightness(3) hue-rotate(30deg);
            transform:          scale(1.018);
        }84%{
            filter:             blur(0) brightness(0) hue-rotate(0);
        }85%{
            filter:             blur(0.5px) brightness(3) hue-rotate(30deg);
            transform:          scale(1.018);
        }100%{
            filter:             blur(0) brightness(1) hue-rotate(0);
        }
    }
}

@media screen and (max-width: 400px) {
    .numbersContainerInner .textInNumbersContainer{
        width:              75px;
    }

    .numbersContainerOuter{
        padding:            0 16px;
    }
}

@media screen and (max-width: 340px) {
    .numbersContainerInner{
        gap:                8px;
    }

    .numbersContainerInner .textInNumbersContainer{
        width:              60px;
    }

    .numbersContainerOuter{
        padding:            0 32px;
    }
}