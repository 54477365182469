.showcaseContainer{
    display:            flex;  
    justify-content:    center;
    align-items:        center;
    gap:                64px;
    width:              60vw;
    z-index:            999;
    background-color: black;
    border-radius: 10px;
}

.showcaseContainer > img{
    cursor:             pointer;
    z-index:            999;
}

video{
    min-width:          60vw;
    width:              60vw;
    max-width:          60vw;
    min-height:         42vw;
}

.showcaseImage{
    border-radius:      10px;
    min-width:          60vw;
    width:              60vw;
    max-width:          60vw;
    height:             40vw;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    overflow:           hidden;
    border-radius:      10px;
}

.showcaseImage img{
    min-width:          60vw;
    width:              60vw;
    max-width:          60vw;
    min-height:         42vw;
}

.gallery{
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    position:           relative;
    max-width:          100vw;
    margin:             0 auto;
}
  
.galleryScroller{
    scroll-snap-type:   x mandatory;
    scroll-snap-align:  start;
    display:            flex;
    align-items:        center;
    gap:                24px;
    height:             22vw;
    scroll-behavior:    smooth;
    overflow-x:         scroll;
    overflow-y:         hidden;
    max-width:          100vw;
}

.galleryScroller::-webkit-scrollbar{
    display:            none;
}

.galleryScroller div{
    min-width:          30.2vw;
    width:              30.2vw;
    max-width:          30.2vw;
    height:             16.9vw;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    overflow:           hidden;
    border-radius:      10px;
    cursor:             pointer;
}

.galleryScroller .videoImage{
    background-color: var(--darkblue);
}
  
.galleryScroller div img{
    min-width:          30.2vw;
    width:              30.2vw;
    max-width:          30.2vw;
    min-height:         16.9vw;
}

.galleryScroller div .video{
    min-width:          100px;
    width:              100px;
    max-width:          100px;
    min-height:         100px;
}

@media screen and (max-width: 1500px){
    .galleryScroller{
        height:             25vw;
    }

    .galleryScroller div{
        min-width:          38vw;
        width:              38vw;
        max-width:          38vw;
        height:             21.4vw;
    }
      
    .galleryScroller div img{
        min-width:          38vw;
        width:              38vw;
        max-width:          38vw;
        min-height:         21.4vw;
    }
}

@media screen and (max-width: 900px){
    .galleryScroller{
        height:             28vw;
    } 

    .galleryScroller div .video{
        min-width:          75px;
        width:              75px;
        max-width:          75px;
        min-height:         75px;
    }
}

@media screen and (max-width: 640px){
    .galleryScroller div .video{
        min-width:          50px;
        width:              50px;
        max-width:          50px;
        min-height:         50px;
    }

    .showcaseContainer{
        gap:                3.75vw;
    }

    .showcaseImage{
        min-width:          70vw;
        width:              70vw;
        max-width:          70vw;
        height:             46.6vw;
    }
    
    .showcaseImage img{
        min-width:          70vw;
        width:              70vw;
        max-width:          70vw;
        min-height:         46.6vw;
    }

    .pastToToday h2{
        margin-top:         -24px;
    }
}

@media screen and (max-width: 500px){
    .showcaseContainer{
        gap:                2.4vw;
    }

    .showcaseImage{
        min-width:          64vw;
        width:              64vw;
        max-width:          64vw;
        height:             42.6vw;
    }
    
    .showcaseImage img{
        min-width:          64vw;
        width:              64vw;
        max-width:          64vw;
        min-height:         42.6vw;
    }
    
    .pastToToday h2{
        margin-top:         -64px;
    }
}

@media screen and (max-width: 400px){
    .showcaseContainer > img{
        transform:          scale(.8);
    }
}

@media screen and (max-width: 300px){
    .galleryScroller div .video{
        min-width:          30px;
        width:              30px;
        max-width:          30px;
        min-height:         30px;
    }

    .showcaseContainer > img{
        transform:          scale(.7);
    }

    .showcaseContainer{
        gap:                1vw;
    }
}