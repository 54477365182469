:root {
    --orange:               #FFC01A;
    --darkblue:             #1A2061;
    --white:                #FFFFFF;
    --turqoise:             #00FFFF;
    --purple:               #FF00FF;
}
/* 
.App{
    animation:              1s ease-in-out forwards infinite backgroundAnim;
}

@keyframes backgroundAnim{
    0%{
        background-size:        5005vw 9000px!important;
        background-position:    15% top;
    }50%{
        background-size:        175vw 9000px!important;
        background-position:    15% top;   
    }100%{
        background-size:        5005vw 9000px!important;
        background-position:    15% top;
    }
} */

html, body, .App, #root{
    width:                  100vw;
    max-width:              100vw;
    overflow-x:             hidden;
}

.App{
    width:                  100vw;
    max-width:              100%;
    overflow-x:             hidden;
    touch-action:           manipulation;
    touch-action:           pan-x pan-y;
    transition:             all 1s;
}

body{
    overflow-x:             hidden;
    background-color:       var(--darkblue);
}
  
::selection{
    background-color:       var(--purple);
    color:                  var(--white);
}

::-webkit-scrollbar{
    width:                  8px;
    box-shadow:             inset 0 10px 10px 10px var(--purple);
}

::-webkit-scrollbar-track{
    box-shadow:             inset 0 10px 10px 10px #00003D;
}
  
::-webkit-scrollbar-thumb{    
    border-radius:          50px;
    box-shadow:             inset 0 10px 10px 10px var(--purple);
    border:                 solid 1px transparent;
}
  
::-webkit-scrollbar-thumb:hover{
    border-radius:          50px;
    box-shadow:             inset 0 10px 10px 10px #64f0f2;
    border:                 solid 1px transparent;
}

.noSelect{
    -webkit-touch-callout:  none;
      -webkit-user-select:  none;
       -khtml-user-select:  none;
         -moz-user-select:  none;
          -ms-user-select:  none;
              user-select:  none;
}

@media screen and (max-width: 1200px) {
    .App{
        background-size:        175vw 9000px!important;
        background-position:    15% top;
    }
}

@media screen and (max-width: 900px) {
    .App{
        background-size:        250vw 6900px!important;
        background-position:    15% top;
    }
}

@media screen and (max-width: 500px) {
    .App{
        background-size:        250vw 6200px!important;
        background-position:    15% top;
    }
}

@media screen and (max-width: 400px) {
    .App{
        background-size:        250vw 6400px!important;
        background-position:    15% top;
    }
}
