.whyExplainerBecause{
    padding:            10px 80px 12px;
    background-color:   var(--purple);
    border-radius:      4px;
    margin-bottom:      32px;
    z-index:            5;
    box-shadow:         0 0 160px #FF00FF,
                        0 0 180px #FF00FF;
}

.whyExplainerItemsContainer{
    display:                grid;
    grid-template-columns:  1fr 1fr 1fr;
    grid-template-rows:     1fr 1fr;
    grid-column-gap:        0px;
    grid-row-gap:           0px;
    width:                  80vw;
    gap:                    64px;
    z-index:                6;
    align-items:            start;
    margin-left:            16px;
    margin-bottom:          32px;
}

.whyExplainerItemsContainer p{
    text-align:         left;
    margin-bottom:      24px;
    text-shadow:        0 0 16px #1A2061,
                        0 0 32px #1A2061;
}

.whyExplainerItemsContainer p::before{
    content:            "";
    width:              0;
    height:             0;
    border-top:         8px solid transparent;
    border-bottom:      8px solid transparent;
    border-left:        8px solid var(--purple);
    position:           absolute;
    margin-top:         6px;
    margin-left:       -20px;
}


@media screen and (max-width: 700px) {
    .whyExplainerItemsContainer{
        gap:                    24px 40px;
    }
}

@media screen and (max-width: 500px) {
    .whyExplainerItemsContainer{
        grid-template-columns:  1fr 1fr;
        grid-template-rows:     1fr 1fr 1fr;
        width:                  80vw;
        gap:                    0px 32px;
    }

    .whyExplainerItemsContainer p::before{
        border-top:         6px solid transparent;
        border-bottom:      6px solid transparent;
        border-left:        6px solid var(--purple);
        margin-top:         4px;
        margin-left:       -16px;
    }
}

@media screen and (max-width: 300px) {
    .whyExplainerItemsContainer{
        display:                flex;
        flex-direction:         column;
        margin-bottom:          8px;
    }

    .whyExplainerItemsContainer p::before{
        border-top:         5px solid transparent;
        border-bottom:      5px solid transparent;
        border-left:        5px solid var(--purple);
        margin-top:         2px;
        margin-left:       -14px;
    }

    .whyExplainerBecause{
        padding:            8px 20vw 10px;
        background-color:   var(--purple);
        margin-bottom:      16px;
    }
}