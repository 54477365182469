.participantsButtonContainer{
    display:            flex;
    justify-content:    center;
    gap:                16px;
}

.participants{
    background-color:   var(--darkblue);
    padding:            64px 0px 48px;
}

.participantsButton{
    color:              var(--purple);
    background-color:   transparent;
    border:             2px solid var(--purple);
    font-size:          1.2rem;
    padding:            7px 16px 8px;
    border-radius:      4px;
    cursor:             pointer;
    z-index:            999;
}

.participantsButtonActive{
    background-color:   var(--purple);
    color:              var(--white);
    border:             none;
    font-size:          1.2rem;
    padding:            9px 16px 10px;
    cursor:             pointer;
}

.participantsSlider{
    display:            flex;
    justify-content:    space-between;
    align-items:        center;
    width:              61.4vw;
    margin:             0 auto;
}

.participantsSlider img{
    align-self:         center;
}

.participantCompanies{
    display:            flex;
    justify-content:    center;
    align-items:        center;
    position:           relative;
    max-width:          80vw;
    margin:             0 auto;
}

.participantCompanies>img{
    margin-top:         32px;
    cursor:             pointer;
    z-index:            999;
}
  
.participantsScroller{
    scroll-snap-type:   x mandatory;
    display:            flex;
    align-items:        center;
    height:             140px;
    margin-top:         32px;
    scroll-behavior:    smooth;
    overflow-x:         scroll;
    overflow-y:         hidden;
}

.participantsScroller::-webkit-scrollbar{
    display:            none;
}
  
.participantsScroller img {
    border-radius:      10px;
    z-index:            999;
}

.participantsScroller span{
    height:             104px;
    width:              1px;
    background-color:   var(--white);
    opacity:            .1;
    margin-left:        2px;
}

#soon{
    width:              80vw;
    display:            flex;
    justify-content:    center;
    background-color:   rgb(255, 255, 255, .25);
    margin:             0 32px;
    padding:            32px 0;
    border-radius:      10px;
    text-align:         center;
    z-index:            999;
}


@media screen and (max-width: 900px){
    .participantsScroller{
        height:             80px;
        margin-top:         48px;
    }

    #soon{
        padding:            20px 0;
    }

    .participantCompanies>img{
        margin-top:             48px;
    }

    .participantsScroller img {
        transform:              scale(.9);
    }
}
  
@media screen and (max-width: 640px){
    .participantCompanies>img{
        transform:              scale(.75);
    }

    .participantsScroller img {
        transform:              scale(.70);
        margin:                 0 -24px;
    }
    
    .participantCompanies{
        margin:                 0 auto;
        width:                  92vw;

    }

    .participantsSlider{
        width:              92vw;
        margin:             0 auto;
    }

    .participants{
        background-color: var(--darkblue);
        padding:            48px 0px 32px;
    }
}
  
