.whatIsInIt{
    background-color:   var(--darkblue);
}

.whatIsInItContainerOuter{
    display:            flex;
    flex-direction:     column;
    gap:                32px;
    margin-top:         16px;
    margin-bottom:      32px;
}

.whatIsInItContainerInner{
    display:            flex;
    gap:                32px;
    align-items:        center;
}

.whatIsInItContainerInner div{
    background-color:   var(--purple);
    color:              var(--white);
    width:              18vw;
    padding:            10px 0 12px;
    border-radius:      4px;
    height:             76px;
    display:            flex;
    justify-content:    center;
    align-items:        center;
}
.whatIsInItContainerInner div p{
    width:              18vw;
    padding:            10px 0 12px;
    border-radius:      4px;
    z-index: 999;
}

.whatIsInItText{
    text-align:         center;
    width:              65vw;
    z-index: 999;
}

@media screen and (max-width: 850px){
    .whatIsInItContainerInner{
        gap:                20px;
    }
    
    .whatIsInItContainerOuter{
        gap:                20px;
    }
    
    .whatIsInItContainerInner div{
        width:              28vw;
    }

    .whatIsInItContainerInner div p{
        width:              24vw;
    }
    
    .whatIsInItText{
        margin-top:         16px;
        width:              85vw;
    }
}

@media screen and (max-width: 550px){
    .whatIsInItContainerOuter{
        margin-top:         0px;
    }

    .whatIsInItContainerInner{
        gap:                16px;
    }
    
    .whatIsInItContainerOuter{
        gap:                16px;
    }
    
    .whatIsInItContainerInner div{
        width:              26vw;
        height:             76px;
    }

    .whatIsInItContainerInner div p{
        width:              24vw;
    }
    
    .whatIsInItText{
        margin-top:         8px;
    }
}