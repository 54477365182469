.whatTheySay{
    margin-top:                 48px;
}

.whatTheySayContainerOuter{
    display:                    flex;
    justify-content:            center;
    gap:                        128px;
    margin-top:                 160px;
}

.whatTheySayContainerInner{
    display:                    flex;
    flex-direction:             column;
    justify-content:            flex-start;
    align-items:                center;
    background-color:           rgba(0, 0, 0, 0.2);
    min-width:                  33.8vw;
    width:                      33.8vw;
    height:                     590px;
    border-radius:              10px;
    transition:                 all .4s ease-in-out;
    backdrop-filter:            blur(10px);
    z-index:                     999;
   -webkit-backdrop-filter:     blur(10px);
}

.whatTheySayContainerImageDiv img{
    width:                      180px;
    border-radius:              500px;
    background-color:           var(--white);
}

.whatTheySayContainer > img{
    cursor:                     pointer;
    z-index:                    999;
}

.whatTheySayContainerImageDiv{
    display:            grid;
    place-items:        center;
    width:              100%;
    height:             0;
    margin-top:         -90px;
    margin-bottom:      88px;
}

.cardName{
    background-color:           var(--purple);
    color:                      var(--white);
    font-size:                  1.5rem;
    font-weight:                600;
    padding:                    6px 3.5vw 7px;
    text-align:                 center;
    width:                      20vw;
    display:                    flex;
    justify-content:            center;
    border-radius:              4px;
    height:                     4ch;
    justify-content:            center;
    align-items:                center;
    margin-top:                 120px;
}

.cardNamePerfetti{
    padding:                    18px 3.5vw 20px;
    font-size:                  1rem;
}

.cardText{
    text-shadow: 0 0 16px hsla(0, 0%, 0%, 0.4);
    font-size:                  1.3rem;
    text-align:                 left;
    width:                      27vw;
    margin-top:                 24px;
}

.cardText span{
    padding:                    0;
    color:                      var(--turqoise);
    padding-top:                160px;
    cursor:                     pointer;
}

.whatTheySayContainer{
    display:            flex;
    justify-content:    center;
    align-items:        center;
    position:           relative;
    max-width:          80vw;
    margin:             0 auto;
    gap:                40px;
}

.whatTheySayScroller{
    scroll-snap-type:   x mandatory;
    scroll-snap-align:  start;
    display:            flex;
    align-items:        center;
    scroll-behavior:    smooth;
    overflow-x:         scroll;
    overflow-y:         hidden;
    padding:            96px 0;
    gap:                40px;
}

.whatTheySayScroller::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width: 1640px) {
    .whatTheySayContainerInner{
        min-width:                  33vw;
        width:                      33vw;
    }
}

@media screen and (max-width: 1480px) {
    .whatTheySayContainerInner{
        min-width:                  32vw;
        width:                      32vw;
    }
}

@media screen and (max-width: 1300px) {
    .whatTheySayContainerInner{
        height:                     745px;
        min-width:                  30.75vw;
        width:                      30.75vw;
    }

    .cardName{
        width:                      19vw;
    }

    .cardText{
        width:                      26vw;
    }
}

@media screen and (max-width: 1100px) {
    .whatTheySayContainer{
        max-width:                  88vw;
    }

    .whatTheySayContainerInner{
        height:                     820px;
        min-width:                  33vw;
    }

    .cardName{
        width:                      20vw;
    }

    .cardText{
        width:                      27vw;
    }
}

@media screen and (max-width: 940px) {
    .whatTheySayContainerInner{
        min-width:                  33vw;
    }

    .whatTheySayScroller{
        gap:                        2vw;
    }
}

@media screen and (max-width: 850px) {
    .whatTheySayContainerInner{
        min-width:                  45vw;
    }

    .cardName{
        width:                      20vw;
    }

    .cardText{
        width:                      32vw;
    }

    .whatTheySayContainer{
        max-width:                  88vw;
    }

    .whatTheySayScroller{
        gap:                        4vw;
    }
}

@media screen and (max-width: 720px) {
    .whatTheySayContainerInner{
        height:                     870px;
    }
}

@media screen and (max-width: 640px) {
    .whatTheySayContainerInner{
        min-width:                  70vw;
        height:                     400px;
    }

    .whatTheySayContainerImageDiv img{
        transform:                  scale(.85);
    }

    .whatTheySayContainerImageDiv{
        margin-bottom:              64px;
    }

    .cardName{
        width:                      35vw;
    }

    .cardText{
        width:                      52vw;
    }

    .whatTheySayContainer{
        gap:                        16px;
    }


    .whatTheySayContainer{
        max-width:                  92vw;
    }
}

@media screen and (max-width: 550px) {
    .whatTheySayContainerInner{
        height:                     450px;
        min-width:                  67.5vw;
    }

    .cardName{
        width:                      47vw;
    }

    .cardText{
        width:                      54vw;
        font-size:                  1.55rem;
    }
}

@media screen and (max-width: 470px) {
    .whatTheySayContainerInner{
        min-width:                  60vw;
        margin-left:                12px;
    }

    .cardName{
        width:                      38vw;
    }

    .cardText{
        width:                      45vw;
    }

    .whatTheySayContainerInner img{
        transform:                  scale(.75);
    }
}

@media screen and (max-width: 400px) {
    .whatTheySayContainerInner{
        height:                     475px;
        min-width:                  61.9vw;
        margin-left:                16px;
    }

    .cardName{
        width:                      37vw;
    }

    .cardText{
        width:                      44vw;
    }

    .whatTheySayContainer{
        max-width:                  92.5vw;
    }

    .whatTheySayContainer{
        gap:                        1vw;
    }
}