.backgroundLights{
    position:               absolute;
    width:                  100vw;
    height:                 1000vh;
    overflow:               hidden;
    max-width:              100vw;
    z-index:                0;
}

.backgroundLight{
    position:               absolute;
    z-index:                0;
    animation:              4s ease-in-out forwards infinite lightAnim;
}

.backgroundLight1{
    top:                    90vh;
}

.backgroundLight2{
    right:                  -120px;
    top:                    330vh;
}

.backgroundLight3{
    right:                  -80px;
    top:                    390vh;
}

.backgroundLight4{
    left:                   -220px;
    top:                    405vh;
}

.backgroundLight5{
    right:                  -220px;
    top:                    155vh;
}

.backgroundLight6{
    right:                  0px;
    top:                    140vh;
}

.backgroundLight7{
    left:                   -88px;
    top:                    495vh;
}

.backgroundLight8{
    left:                  -120px;
    top:                    140vh;
}

.backgroundLight9{
    left:                  -80px;
    top:                    330vh;
}

.backgroundLight10{
    right:                  -220px;
    top:                    -40vh;
}

.backgroundLight11{
    left:                  -200px;
    top:                    190vh;
}

@keyframes lightAnim{
    0%{
        filter:             blur(0);
    }20%{
        filter:             blur(3px) brightness(3) hue-rotate(10deg);
        transform:          scale(1.015);
    }30%{
        filter:             blur(0);
    }74%{
        filter:             blur(0);
    }82%{
        filter:             blur(3px) brightness(3) hue-rotate(10deg);
        transform:          scale(1.015);
    }89%{
        filter:             blur(0);
    }92%{
        filter:             blur(3px) brightness(3) hue-rotate(10deg);
        transform:          scale(1.015);
    }100%{
        filter:             blur(0);
    }
}

@keyframes lightAnimIOSChrome{
    0%{
        filter:             blur(0);
    }20%{
        filter:             blur(1.2px) brightness(2.5) hue-rotate(15deg);
        transform:          scale(1.01);
    }30%{
        filter:             blur(0);
    }74%{
        filter:             blur(0);
    }82%{
        filter:             blur(1.2px) brightness(2.5) hue-rotate(15deg);
        transform:          scale(1.01);
    }89%{
        filter:             blur(0);
    }92%{
        filter:             blur(1.2px) brightness(2.5) hue-rotate(15deg);
        transform:          scale(1.01);
    }100%{
        filter:             blur(0);
    }
}

@keyframes lightAnimSafari{
    0%{
        filter:             blur(0);
    }20%{
        filter:             blur(.5px) brightness(1.3) hue-rotate(10deg);
        transform:          scale(1.01);
    }30%{
        filter:             blur(0);
    }74%{
        filter:             blur(0);
    }82%{
        filter:             blur(.5px) brightness(1.3) hue-rotate(10deg);
        transform:          scale(1.01);
    }89%{
        filter:             blur(0);
    }92%{
        filter:             blur(.5px) brightness(1.3) hue-rotate(10deg);
        transform:          scale(1.01);
    }100%{
        filter:             blur(0);
    }
}

@media screen and (max-width: 1200px){
    .backgroundLight1{
        top:                    130vh;
        left:                   -180px;
    }

    .backgroundLight2{
        right:                  -120px;
        top:                    310vh;
    }

    .backgroundLight5{
        right:                  -280px;
        top:                    174vh;
    }

    .backgroundLight6{
        right:                  -128px;
    } 

    .backgroundLight8{
        left:                  -120px;
        top:                    150vh;
    }

    .backgroundLight9{
        left:                  -64px;
        top:                    320vh;
    }
    
    .backgroundLight11{
        left:                  -240px;
        top:                    180vh;
    }
}


@media screen and (max-width: 900px) {
    .backgroundLight{
        animation:              4s ease-in-out forwards infinite lightAnim900px;
    }
    
    @keyframes lightAnim900px{
        0%{
            filter:             blur(0);
        }20%{
            filter:             blur(2px) brightness(3);
            transform:          scale(1.018);
        }30%{
            filter:             blur(0);
        }74%{
            filter:             blur(0);
        }82%{
            filter:             blur(2px) brightness(3);
            transform:          scale(1.018);
        }89%{
            filter:             blur(0);
        }92%{
            filter:             blur(2px) brightness(3);
            transform:          scale(1.018);
        }100%{
            filter:             blur(0);
        }
    }
}

@media screen and (max-width: 640px){
    .backgroundLight1{
        left:                   -40px;
        top:                    -8vh;
        width:                  150px;
    }

    .backgroundLight2{
        right:                  -120px;
        top:                    340vh;
    }
    
    .backgroundLight5{
        right:                  -280px;
        top:                    142vh;
    }
        
    .backgroundLight7{
        left:                   -96px;
        top:                    420vh;
    }

    .backgroundLight8{
        left:                   -40px;
        top:                    50vh;
        width:                  150px;
    }

    .backgroundLight9{
        left:                  -104px;
        top:                    330vh;
    }

    .backgroundLight10{
        right:                  -260px;
        top:                    -45vh;
    }
    
        
    .backgroundLight11{
        left:                  -270px;
        top:                    190vh;
    }

}
