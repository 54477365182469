.header{
    margin:             40px;
    display:            flex;
    align-items:        center;
    justify-content:    center;
    height:             100vh;
}

#iframe{
    display:            none;
}

form{
    border-radius:      20px;
    background-color:   #1A2061;
}

#passwordContainer input{
    font-size:          1.2rem;
    border-radius:      5px;
    padding:            16px 32px;
    background-color:   #1A2061;
    color:              aliceblue;
    outline:             white;
    border:             2px solid white;
}

.bannerImage{
    transform:          scale(.5);
    position:           absolute;
    width:              0;
    height:             0;
    display:            grid;
    place-items:        center;
    text-align:         center;
    margin:             0 auto;
}

.bannerImage img{
    transform-origin:   center;
    position:           absolute;
    top:                50;
    animation:          headerAnim 8s ease-in-out forwards infinite .22s
}

.bannerImagePart1{
    top:                80px;
    left:               250px;
}

.bannerImagePart2{
    top:                -690px;
    left:               -720px;
}

.bannerImagePart3{
    top:                100px;
    left:               -780px;
}

.bannerImagePart4{
    top:                -650px;
    right:              -950px;
}

.bannerImagePart5{
    transform:          scale(1.5);
    top:                -520px;
    left:               -750px;
}

.bannerImagePart6{
    top:                -250px;
    left:               650px;
}

.bannerImagePart7{
    top:                300px;
    left:               50px;
}

.bannerImagePart8{
    top:                60px;
    left:               -560px;
}

.bannerImagePart9{
    top:                -320px;
    left:               -850px;
}

.bannerImagePart10{
    top:                -640px;
    left:               -300px;
}

.bannerImagePart11{
    top:                -128px;
    left:               750px;
}

.bannerImagePart12{
    top:                -670px;
    left:               0;
}

.bannerImagePart13{
    top:                -120px;
    left:               -1050px;
}

.bannerImagePart14{
    top:                -450px;
    left:               800px;
}

.bannerImagePart15{
    top:                -450px;
    left:               -1150px;
}

.bannerImagePart16{
    transform:          scale(1.25)!important;
    top:                250px;
    text-align:         center;
}

.header h1{
    line-height:        100px;
    font-size:          5.75rem;
    font-weight:        400;
    animation:          8s ease-in-out forwards infinite headerAnim;
}

.header h1 .bold{
    font-weight:        800;
}

.header h1 .yellow{
    color:              yellow;
}

@keyframes headerAnim{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }81%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.2%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.3%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }84%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }85%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

@keyframes headerPartAnim{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }83.3%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }85%{
        filter:             blur(.2px) brightness(1.2) hue-rotate(12deg) contrast(2);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

@keyframes headerAnimSafari{
    0%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }8%{
        filter:             blur(.2px) brightness(1.5) hue-rotate(12deg);
    }18%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }30%{
        filter:             blur(.2px) brightness(1.5) hue-rotate(12deg);
    }47%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }60%{
        filter:             blur(.2px) brightness(1.5) hue-rotate(12deg);
    }70%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }80%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }81%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.2%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }83.3%{
        filter:             blur(.2px) brightness(1.5) hue-rotate(12deg);
    }84%{
        filter:             blur(0) brightness(0) hue-rotate(0);
    }85%{
        filter:             blur(.2px) brightness(1.5) hue-rotate(12deg);
    }100%{
        filter:             blur(0) brightness(1) hue-rotate(0);
    }
}

.locationContainer{
    position:           absolute;
    left:               80px;
    bottom:             48px;
    display:            flex;
    justify-content:    center;
    align-items:        center;    
    gap:                8px;
}

.locationContainer p{
    font-size:          1.1rem;
}

.locationContainer img{
    transform:          scale(.8);
}

.headerLogo{
    position:           absolute;
    right:              80px;
    bottom:             48px;
    transform:          scale(.8);
}

@media screen and (max-width: 1200px) {
    .bannerImage{
        transform:          scale(.45);
    }

    .header h1{
        line-height:        80px;
        font-size:          4.5rem;
    }

    .bannerImagePart11{
        top:                -50px;
        left:               550px;
    }
    
    .bannerImagePart14{
        top:                -520px;
        left:               700px;
    }
    
    .bannerImagePart16{
        top:                200px;
    }
}

@media screen and (max-width: 950px) {
    .bannerImage{
        transform:          scale(.38);
    }

    .header h1{
        line-height:        72px;
        font-size:          4.4rem;
    }

    .bannerImagePart11{
        top:                -50px;
        left:               500px;
    }
    
    .bannerImagePart14{
        top:                -520px;
        left:               600px;
    }
    
    .bannerImagePart15{
        top:                -450px;
        left:               -1100px;
    }
    
    .bannerImagePart16{
        top:                220px;
    }
}

@media screen and (max-width: 700px) {
    .header h1{
        line-height:        72px;
        font-size:          4.4rem;
    }

    .bannerImagePart11{
        top:                -50px;
        left:               500px;
    }

    .bannerImagePart13{
        top:                -120px;
        left:               -1000px;
    }

    .bannerImagePart14{
        display:            none;
    }
    
    .bannerImagePart15{
        display:            none;
    }
    
    .bannerImagePart16{
        top:                220px;
    }
}

@media screen and (max-width: 640px) {
    .bannerImage{
        transform:          scale(.32);
    }

    .header h1{
        line-height:        72px;
        font-size:          5rem;
    }

    .locationContainer{
        left:               5vw;
        bottom:             5vh;
        gap:                0;
    }

    .locationContainer img{
        transform:          scale(.65);
    }

    .headerLogo{
        right:              5vw;
        bottom:             5vh;
        transform:          scale(.65);
    }

    .bannerImagePart6{
        display:            none;
    }
    
    .bannerImagePart11{
        top:                400px;
        left:               350px;
    }
        
    .bannerImagePart16{
        top:                260px;
    }
}

@media screen and (max-width: 500px) {
    .bannerImage{
        margin-top:         -128px;
        transform:          scale(.17);
    }

    .header h1{
        margin-top:         -128px;
        line-height:        48px;
        font-size:          3.5rem;
    }

    .bannerImagePart13{
        top:                120px;
        left:               -1000px;
    }
    
    .bannerImagePart8{
        top:                400px;
        left:               -560px;
    }

    .locationContainer{
        left:               6vw;
        bottom:             2vh;
    }

    .locationContainer p{
        margin-top:         4px;
        margin-left:        -8px;
    }

    .locationContainer img{
        transform:          scale(.5);
    }

    .headerLogo{
        right:              6vw;
        bottom:             2vh;
        transform:          scale(.5);
    }
    
    .header h1 img{
        animation:          8s ease-in-out forwards infinite headerAnim550px;
    }

    @keyframes headerAnim550px{
        0%{
            filter:             brightness(1) hue-rotate(0);
        }8%{
            filter:             brightness(1.5) hue-rotate(15deg) saturate(2);
        }18%{
            filter:             brightness(1) hue-rotate(0);
        }30%{
            filter:             brightness(1.5) hue-rotate(15deg) contrast(2);
        }47%{
            filter:             brightness(1) hue-rotate(0);
        }60%{
            filter:             brightness(1.5) hue-rotate(15deg) saturate(2);
        }70%{
            filter:             brightness(1) hue-rotate(0);
        }80%{
            filter:             brightness(1) hue-rotate(0);
        }81%{
            filter:             brightness(0) hue-rotate(0);
        }83.2%{
            filter:             brightness(0) hue-rotate(0);
        }83.3%{
            filter:             brightness(1.5) hue-rotate(15deg) saturate(2);
        }85%{
            filter:             brightness(0) hue-rotate(0);
        }86%{
            filter:             brightness(1.5) hue-rotate(15deg) saturate(2);
        }100%{
            filter:             brightness(1) hue-rotate(0);
        }
    }
}

@media screen and (max-width: 340px) {
    .locationContainer{
        left:               2.5vw;
        bottom:             vh;
    }

    .locationContainer p{
        margin-top:         4px;
        margin-left:        -12px;
    }

    .locationContainer img{
        transform:          scale(.4);
    }

    .headerLogo{
        right:              2.5vw;
        bottom:             vh;
        transform:          scale(.4);
    }
}
