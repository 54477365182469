footer{
    background-color:           rgb(26, 32, 97, 0.5);
    display:                    flex;
    justify-content:            space-around;
    align-items:                center;
    margin-top:                 128px;
    padding:                    24px 0;
    backdrop-filter:            blur(9.1px);
    -webkit-backdrop-filter:    blur(9.1px);
}

.footerTextContainer{
    display:                    flex;
    align-items:                center;
    font-size:                  .9rem;
}

.footerTextContainer a{
    transform:                  scale(.5);
    margin:                     0 -16px;
}

.footerInfoContainerOuter{
    display:                    flex;
    flex-direction:             column;
    gap:                        8px;
}

.footerInfoContainerInner{
    display:                    flex;
    gap:                        8px;
}

.footerInfoContainerInner img{
    align-self:                 center;
}

.footerInfoContainerOuter p, .footerTextContainer p{
    font-size:                  1.2rem;
}


@media screen and (max-width: 1000px) {
    footer{
        padding:                    8px 0;
    }
    
    .footerTextContainer a{
        transform:                  scale(.4);
        margin:                     0 -26px;
    }
    
    .footerInfoContainerInner{
        gap:                        6px;
    }
    
    .footerInfoContainerOuter p, .footerTextContainer p{
        font-size:                  1rem;
    }
}

@media screen and (max-width: 790px) {
    footer{
        flex-direction:             column;
    }
    
    .footerInfoContainerOuter{
        flex-direction:             column-reverse;
        align-items:                center;   
        gap:                        32px; 
        margin-bottom:              32px;
    }
    
    .footerInfoContainerOuter p, .footerTextContainer p{
        font-size:                  1rem;
    }
}

@media screen and (max-width: 640px) {
    footer{
        margin-top:                 80px;
    }

    .footerTextContainer a{
        transform:                  scale(.25);
        margin:                     0 -40px;
    }
}

@media screen and (max-width: 500px){
    footer{
        margin-top:                 28px;
    }

    .footerInfoContainerOuter{
        gap:                        24px; 
    }
}