nav{
    background-color:       rgba(26, 32, 97, 0.75);
    color:                  var(--white);
    width:                  89.65vw;
    height:                 32px;
    position:               fixed;
    top:                    0;
    right:                  0;
    display:                flex;
    justify-content:        space-between;
    align-items:            center;
    padding:                28px 6vw 24px 6vw;
    border-radius:          0 0 16px 16px;
    z-index:                9999;
    backdrop-filter:         blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.mobileNavBar{
    display:                none;
}

.navLogo{
    transform:              scale(.8);
    cursor:                 pointer;
}

#navBarLinksContainer, .mobileNavBarLinksContainer{
    display:                flex;
    gap:                    64px;
}

#navBarLinksContainer li{
    cursor:                 pointer;
}

#navBarSocialsContainer{
    display:                flex;
    gap:                    8px;
}

@media screen and (max-width: 1400px) {
    #navBarLinksContainer{
        margin-left:           -64px;
        gap:                    16px;
    }
    
    #navBarSocialsContainer{
        gap:                    4px;
    }

    nav{
        width:                  95vw;
        height:                 32px;
        padding:                28px 2.5vw 24px 2.5vw;
        border-radius:          0 0 16px 16px;
    }
}

@media screen and (max-width: 900px) {
    .navLogo{
        cursor:                 pointer;
    }

    #navBarSocialsContainer, #navBarLinksContainer{
        display:                none;
    }

    nav{
        width:                  95vw;
        padding:                28px 2.5vw 24px 2.5vw;
        border-radius:          0 0 0 0;
        height:                 24px;
    }

    .mobileNavBar{
        display:                unset;
    }

    .hamburgerMenu{
        display:                flex;
        flex-flow:              column wrap;
        justify-content:        space-between;
        height:                 36px;
        width:                  36px;
        cursor:                 pointer;
    }

    .barTop, .barMiddle, .barBottom{
        background:             white;
        height:                 4px;
        border-radius:          4px;
        margin:                 4px 0;
        transform-origin:       left;
        transition:             all 0.5s;
    }

    .hamburgerMenuActive .barTop{
        transform:              rotate(45deg);
    }

    .hamburgerMenuActive .barMiddle{
        transform:              translateX(1rem);
        opacity:                0;
    }

    .hamburgerMenuActive .barBottom{
        transform:              rotate(-45deg);
    }

    .mobileNavBarLinksContainer{
        position:               absolute; 
        display:                flex;
        flex-direction:         column;
        height:                 calc(100vh - 212px);
        right:                  0;
        padding:                64px 64px 64px 40px;
        text-align:             right;
        margin-top:             18px;
        opacity:                0;
        background-color:       rgba(26, 32, 97, 0.75);
        backdrop-filter:         blur(10px);
        -webkit-backdrop-filter: blur(10px);
        transform:              translateX(256px);
        transition:             1s all ease-in-out;
        border-radius:          510% 0 0 510%;
        max-width:              1px;
    }

    .mobileNavBarLinksContainer li{
        white-space:            nowrap;
    }

    .mobileNavBarLinksContainerActive{
        opacity:                1;
        transform:              translateX(0);
        max-width:              128px;
        border-radius:          0 0 0 0;
    }
    
    .mobileNavBarLinksContainer li:last-of-type{
        display:                flex;
        justify-content:        space-between;
        margin-right:           -2px;
    }

    .mobileNavBarLinksContainer li a, .mobileNavBarLinksContainer li a img{
        width:                  21.6px!important;
        height:                 21.6px!important;
    }
}

@media screen and (max-width: 500px) {
    .navLogo{
        transform:              scale(.55);
    }

    .hamburgerMenu{
        transform:              scale(.7);
    }

    #navBarSocialsContainer, #navBarLinksContainer{
        display:                none;
    }

    nav{
        width:                  100vw;
        padding:                28px 3vw 24px 3vw;
        border-radius:          0 0 0 0;
        height:                 8px;
    }

    .mobileNavBarLinksContainer{
        height:                 calc(100vh - 212px);
        margin-top:             10px;
        transform:              translateX(148px);
        transition:             all 1s ease-in-out;
        border-radius:          510% 0 0 510%;
        padding:                112px 40px 64px 48px;
        backdrop-filter:         blur(10px);
        -webkit-backdrop-filter: blur(10px);

    }

    .mobileNavBarLinksContainer li{
        font-size:              1.3rem;
    }

    .mobileNavBarLinksContainerActive{
        opacity:                1;
        transform:              translateX(0);
        max-width:              256px;
        border-radius:          0 0 0 0;
    }
}